import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import TwoCountriesImgSource from 'images/blog-images/n95-mask-china-quality.png';
import SpainImgSource from 'images/blog-images/mouth-mask-standards-from-china-according-to-spain.png';
import MouthMaskImgSource from 'images/blog-images/mouth-mask-standards-from-china.png';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query TwoCountriesBlogImg {
      file(relativePath: { eq: "n95-mask-china-quality.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo warehouse and fulfillment cover"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="China’s Protection Mask Standards Equivalent to European | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-chinas-protection-mask" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "Protective face maskS shortage amid COVID 19,2 countries issued a resolution to announce that standards regarding the Chinese face masks can replace the European.",
            },
            {
              property: `og:title`,
              // content: "China’s Protection Mask Standards Equivalent to European | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-chinas-protection-mask" }),
            },
            {
              property: `og:description`,
              content: "Protective face maskS shortage amid COVID 19,2 countries issued a resolution to announce that standards regarding the Chinese face masks can replace the European.",
            },
            {
              property: `og:image`,
              content: TwoCountriesImgSource,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="protection-mask-standards-equivalent-to-european"
        title="Two Countries Announced China’s Protection Mask Standards Equivalent to European Ones"
        date="2020-04-30"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <FirstTitle>Spain announces that mask-related Chinese standards can replace European standards</FirstTitle>
            
            <p>
              In response to the current situation of the market shortage of personal protective face masks with the CE mark during the COVID-19 epidemic, the Spanish Ministry of Industry and SMEs recently issued a resolution to announce that standards regarding the Chinese face masks can replace the European standards.
            </p>

            <Image src={SpainImgSource} alt="Mouth mask standards from China according to spain"/>

            <p>
              According to the resolution, during the epidemic, in addition to obtaining the CE mark through compliance with the harmonized standards, Spain will also allow the use of protective masks that comply with the Chinese standard GB 2626 (protection level KN95), but need to meet the following requirements:
            </p>

            <OrderedList>
              <li>under the authorization of the Ministry of Health, publicly purchased protective masks that do not have the CE mark but meet the Chinese standard GB 2626 (protection level KN95) for use by medical personnel;</li>
              <li>protection masks without the CE mark are verified by the authorized agency and proved to meet the Chinese standard GB 2626 (protection level KN95) .At the same time, the necessary procedures for obtaining the CE mark are carried out and can be temporarily sold during the epidemic;</li>
              <li>comply with the Chinese standard GB 2626 (protection level KN95), and meet the basic health and safety requirements stipulated in the relevant medical device regulations of the European Union for obtaining the CE mark.</li>
            </OrderedList>  

            <Image src={TwoCountriesImgSource} className="no-top" alt="n95 mask china quality"/>

            <h5>The Netherlands announces that some Chinese mask standards are equivalent to European standards</h5>
            
            <p>
              Recently, the official website of the Dutch Health Care and Youth Inspection Bureau announced that to ensure the adequate supply of personal protective equipment and medical device products during the outbreak, the Dutch Ministry of Social Affairs and Employment (SZW) and the Netherlands National Institute of Public Health and Environment (RIVM) Evaluation, some Chinese mask standards are equivalent to related European standards.
            </p>

            <Image src={MouthMaskImgSource} alt="Mouth mask standards from China "/>

            <p>
              According to: <a href="https://www.igj.nl/" target="_blank" rel="noreferrer">https://www.igj.nl/</a>
            </p>

            <p>
              It concerns the following equivalent standards:
            </p>
            
            <UnorderedList>
              <li>The Chinese GB 2626-2006 standard (followed by the GB 2626-2019 standard) for the nasal masks KN95, KP95 (FFP2) and KN100, KP100 (FFP3) are equivalent to EN 149: 2001 + A1: 2009;</li>
              <li>The Chinese GB 2626-2006 standard (followed by the GB 2626-2019 standard) for the KN95 mouth mask is also equivalent to NEN-EN 14683: 2019 when the KN95 mask is marketed as a surgical mask.</li>
              <li>The American NIOSH 42 CFR 84 standard for masks N95, P95, R95 (FFP2) and N100, P100, R100, P99, R99 (FFP3) is equivalent to EN 149: 2001 + A1: 2009.</li>
            </UnorderedList>  

            <p>
              If Chinese KN95 masks enter the Dutch market as medical-surgical masks, GB 2626-2006 is equivalent to the Dutch national standard NEN-EN 14683: 2019 (Requirements and Test Methods for Medical Masks).
            </p>

            <p>
              In response to the shortage of anti-epidemic materials, the Dutch government coordinated national protection products and mobilized various departments to supervise products without CE marking.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;